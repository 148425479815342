import React, { useState, useEffect } from 'react';
import { LiFiWidget } from '@lifi/widget';

const Widget = ({path}) => {
    // TODO: remove border when screen is smaller than 420px wide
    // get CSS variables from bootstrap
    let computedStyle = getComputedStyle(window.document.querySelector(':root'));
    let borderWidth = computedStyle.getPropertyValue ('--bs-border-width');
    let borderColor = computedStyle.getPropertyValue ('--bs-border-color');
    let primaryColor = computedStyle.getPropertyValue ('--bs-primary');
    let secondaryColor = computedStyle.getPropertyValue ('--bs-secondary');
    let borderRadius = computedStyle.getPropertyValue ('--bs-border-radius');
    let bgDefaultColor = computedStyle.getPropertyValue ('--bs-body-bg');
    let bgPaperColor = computedStyle.getPropertyValue ('--bs-tertiary-bg');

    const borderBreakpoint = 420;

    // need to pass object as prop
    const style = {
        marginTop: '0px' ,
        marginBottom: '64px',
        position: "relative",
        paddingBottom: "208px"
    };

    let [config, setConfig] = useState ({
        containerStyle: {
            border: window.innerWidth <= borderBreakpoint ? "none" : `${borderWidth} solid ${borderColor}`,
            borderRadius: borderRadius
        },
        variant: 'expandable',
        appearance: 'auto',
        theme: {
            palette: {
                primary: { main: primaryColor },
                secondary: { main: secondaryColor },
                background: {
                    default: bgDefaultColor,
                    paper: bgPaperColor
                }
            }
        },
        hiddenUI: ['poweredBy']
    });

    useEffect(() => {
        const handleResize = () => {
            setConfig ({
                ...config,
                containerStyle: {
                    ...config.containerStyle,
                    border: window.innerWidth <= borderBreakpoint ? "none" : `${borderWidth} solid ${borderColor}`
                }
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [borderColor, borderWidth, config]);

    return (
        <div style={style} >
            <LiFiWidget config={config} integrator="Corya_Enterprises" />
        </div>
    );
};

export default Widget;
